<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <v-container
            fluid
            grid-list-md>
            <template v-if="complete">
                <div class="pa-5">
                    <div class="mb-4">
                        <h3 class="primary--text font-weight-regular">
                            Resend Page Access Request Received!
                        </h3>
                        <p>
                            Your request has been successfully submitted and is currently processing.
                            As a result the following communications will be triggered within the next few minutes:
                        </p>
                        <ol class="mb-4">
                            <li
                                v-for="communication in communicationsList"
                                :key="communication">
                                {{ communication }}
                            </li>
                        </ol>
                        <p v-if="communicationsList.length > 2">
                            <em>
                                Note that we send access requests for both business managers because
                                we need page access in {{ dealer.agency.name }} for advertising services
                                and BuyerBridge to post marketplace listings.
                            </em>
                        </p>
                    </div>
                    <div class="text-xs-center">
                        <v-btn
                            depressed
                            outline
                            color="primary"
                            class="text-none font-weight-bold"
                            @click="$emit('input', false)">
                            Close
                        </v-btn>
                    </div>
                </div>
            </template>

            <template v-else>
                <v-alert
                    :value="error"
                    type="error"
                    transition="fade-transition"
                    class="mt-2 mx-2">
                    An error occurred.  Please try again or <a @click="$intercom.show()">contact support</a>.
                </v-alert>
                <v-form
                    id="change-page-form"
                    ref="form"
                    v-model="valid"
                    class="px-3 py-1"
                    @submit.prevent="changePageUrl">
                    <h2 class="primary--text font-weight-regular">
                        Resend Page Access Requests
                    </h2>
                    <p class="mb-5">
                        This tool will allow you to resend the page access requests
                        for {{ dealer.name }}'s page.  For more information on page access
                        <a
                            href="https://support.buyerbridge.com/knowledge/facebook-page-access"
                            target="_blank">
                            click here
                        </a>.
                    </p>

                    <page-access-communications
                        :dealer="dealer"
                        :page="page"
                        :communications-list="communicationsList"
                        :to-emails.sync="toEmails"
                        :cc-emails.sync="ccEmails" />

                    <div class="text-xs-center mb-5">
                        <v-btn
                            type="submit"
                            form="change-page-form"
                            :loading="loading"
                            depressed
                            outline
                            large
                            color="primary"
                            class="text-none font-weight-bold">
                            Submit
                        </v-btn>
                    </div>
                </v-form>
            </template>
        </v-container>
    </styled-slideout>
</template>

<script>
import { facebookUrlRules, requiredRules } from '../../helpers/validationRules';
import PageAccessCommunications from './PageAccessCommunications';
import StyledSlideout from './StyledSlideout';

export default {
    components: {
        PageAccessCommunications,
        StyledSlideout
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        page: {
            type: Object,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isActive: false,
            pageUrl: '',
            valid: false,
            loading: false,
            error: false,
            facebookUrlRules,
            requiredRules,
            complete: false,
            toEmails: [],
            ccEmails: []
        };
    },
    computed: {
        communicationsList() {
            let items = [];

            if (this.page.agency_bm_page_access) {

                const agencyName = this.dealer.agency.name;

                items = items.concat([
                    `An email to the contacts above with instructions give page access to ${agencyName}`,
                    `A Facebook notification to admins of ${this.dealer.name}'s page to give access to ${agencyName}`
                ]);
            }

            if (this.page.buyerbridge_bm_page_access) {
                items = items.concat([
                    'An email to the contacts above with instructions give page access to BuyerBridge',
                    `A Facebook notification to admins of ${this.dealer.name}'s page to give access to BuyerBridge`
                ]);
            }

            return items;
        }
    },
    watch: {
        value(newValue, oldValue) {
            // If we're transitioning to open and the form was previously
            // submitted reset it
            if (newValue === true
                && oldValue === false
                && this.complete === true) {
                this.complete = false;
            }
        }
    },
    methods: {
        async changePageUrl() {

            this.$refs.form.validate();

            if (!this.valid) {
                return;
            }

            /* this.loading = true;
            this.error = false;
            setTimeout(() => {
                this.complete = true;
                this.$emit('complete', {})
                this.loading = false;
            }, 2000) */

            this.loading = true;
            this.error = false;

            const data = {
                sync: true,
                // To allow backwards compatibility the backend expects strings here :(
                override_email_to: JSON.stringify(this.toEmails.map(item => item.value)),
                override_email_cc: JSON.stringify(this.ccEmails.map(item => item.value)),
            };

            try {
                const response = await this.$http.post(`dealers/${this.dealer.id}/page`, data);
                this.complete = true;
                this.$emit('complete', response.data.data);

            } catch(error) {
                // eslint-disable-next-line no-console
                console.error('Error changing page:', error);
                this.error = true;
                this.$emit('error', error);
            } finally {
                this.loading = false;
            }

        },
    }
};
</script>
